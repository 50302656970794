import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  /**
   * Nachbau von Bootstrap 5 Alert Close

    .alert.alert-success.alert-dismissible.fade.show(role="alert" data-controller='alert-close')
      button(type="button" class="close" data-action='alert-close#close' aria-label="Close")
        span aria-hidden="true"
          |&times;
  */
  close() {
    this.element.classList.remove('show')
    const isAnimated = this.element.classList.contains('fade')
    if (isAnimated) {
      setTimeout(() => {
        this.element.remove()
        this.disconnect()
      }, 500)

    } else {
      this.element.remove()
      this.disconnect()
    }
  }
}
